<template>
  <div class="member-container" v-if="currentMember">
    <div class="grid">
      <div class="center-flex">
        <h2 class="name">{{ currentMember.name }}</h2>
        <h3 class="title">{{ currentMember.title }}</h3>
        <a
          class="lknd"
          :href="currentMember.linkedinUrl"
          target="_blank"
          rel="noopener"
        >
          <img
            src="../../assets/icons/orangeLinkedin.svg"
            alt="LinkedIn icon"
          />
        </a>
        <p class="description">{{ currentMember.description }}</p>
        <div class="images">
          <img
            v-for="(image, index) of currentMember.images.map((im) => im.image)"
            :key="image.altText + index"
            :class="'img' + index"
            :src="image.sourceUrl"
            :alt="image.altText"
            :srcset="image.srcSet"
            sizes="(max-width: 750px) 77vw,(max-width: 1100px) 25vw, 17vw"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MEMBERS_QUERY } from "@/gql-operations/allGqlOperations";

export default {
  name: "teamMember",
  apollo: {
    getData: {
      query: MEMBERS_QUERY,
      update(data) {
        const teamMembers = data.teamMembers.nodes.map(
          (node) => node.teamMemberFields.content
        );
        this.$store.commit("setTeamMembers", teamMembers);
      },
    },
  },
  computed: {
    currentMember() {
      const teamMemberName = this.$route.params?.member;
      try {
        return this.$store.getters.getTeamMember(teamMemberName);
      } catch (e) {
        return null;
      }
    },
  },
  watch: {
    currentMember() {
      try {
        document.querySelector("#rightPanel").scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } catch (e) {
        //
      }
    },
  },
};
</script>

<style scoped lang="scss">
.member-container {
  .grid {
    @include right-panel-grid;

    .center-flex {
      grid-column: 2 / span 10;

      .name {
        margin-bottom: 3vh;
      }
      .title {
        color: $orange;
      }
      .description {
        white-space: pre-wrap;
      }
      .images {
        margin-top: 7vh;
        $gap: 1rem;
        display: flex;
        flex-direction: row;
        gap: $gap;
        flex-wrap: nowrap;
        justify-content: space-between;
        img {
          width: calc(33% - #{$gap});
          object-fit: cover;
        }

        .img1 {
          position: relative;
          top: -20px;
        }
      }
    }
  }
}

@media screen and (max-width: $xs-screen) {
  .member-container {
    .grid {
      .center-flex {
        grid-column: 1 / span 12;

        .images {
          margin-top: 7vh;
          $gap: 1rem;
          flex-direction: column;
          gap: $gap;

          img {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
          }

          .img1 {
            position: relative;
            top: 0;
          }
        }
      }
    }
  }
}
</style>
